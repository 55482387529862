import { Controller } from "stimulus"
import Glide from '@glidejs/glide'
export default class extends Controller {

  initialize() {
    console.log("retailer slider initialized")
    this.initializeSlider()
  }

  initializeSlider () {
    const sliders = document.querySelectorAll('.retailer_slider')
    
    for (var i = 0; i < sliders.length; i++) {
      console.log(i)
      var glide = new Glide(sliders[i], {
        type: 'carousel',
        autoplay: 4000,
        animationDuration: 1000,
        gap: 50,
        breakpoints: {
          3000: {
            perView: 4,
            gap: 50,
          },
          1700: {
            perView: 3,
            gap: 50,
          },
          992: {
            perView: 2,
            gap: 50,
          },
          768: {
            perView: 1,
            gap: 30
          },
          576: {
            perView: 1,
            gap: 15
          }
        }
      })
      console.log(glide)
      glide.mount();
    }
  }
}