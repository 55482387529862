import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {

  modelHover() {
    // console.log("mouseover");
    const front = this.element.querySelector('.front')
    if (front != null) {
      front.classList.add('animate__fadeOut');
    }
  }

  modelExit() {
    // console.log("mouseout");
    const front = this.element.querySelector('.front')
    if (front != null) {
      front.classList.remove('animate__fadeOut')
    }
  }

}