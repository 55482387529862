import { Controller } from "stimulus"
import Glide from '@glidejs/glide'
export default class extends Controller {

  initialize() {
    console.log("slider initialized")
    this.initializeSlider()

    load_hi_res()
  }

  initializeSlider () {
    const sliders = document.querySelectorAll('.slider')
    
    for (var i = 0; i < sliders.length; i++) {
      console.log(i)
      var glide = new Glide(sliders[i], {
        type: 'carousel',
        autoplay: 4000,
        animationDuration: 1000,
        gap: 50,
        breakpoints: {
          3000: {
            perView: 4,
            peek: {
              before: 100, 
              after: 100
            },
            gap: 50,
          },
          1700: {
            perView: 3,
            peek: {
              before: 130, 
              after: 130
            },
            gap: 50,
          },
          992: {
            perView: 2,
            peek: {
              before: 100, 
              after: 100
            },
            gap: 50,
          },
          768: {
            perView: 1,
            peek: {
              before: 100, 
              after: 100
            },
            gap: 30
          },
          576: {
            perView: 1,
            peek: {
              before: 100, 
              after: 100
            },
            gap: 15
          }
        }
      })
      console.log(glide)
      glide.mount();
    }
  }
}