import Rails, { $ } from "@rails/ujs"
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import "controllers"
import "animate.css"

import { Application } from "stimulus"
import Select2Controller from 'stimulus-select2'

const application = Application.start()
application.register("select2", Select2Controller)

Rails.start()
ActiveStorage.start()

