import { Controller } from "stimulus"
import Glide from '@glidejs/glide'
export default class extends Controller {

  initialize() {
    console.log("holder initialized")
    this.initializeSlider()
  }

  initializeSlider () {
    console.log("slider")
    
    if (window.innerWidth < 769) {
      document.getElementById('track').classList.add('glide__track')
      document.getElementById('slides').classList.add('glide__slides')
      document.getElementById('bullets').classList.add('glide__bullets')
      
      new Glide('#photos_holder', {
        type: 'carousel',
        autoplay: 3000,
        rewind: true
      }).mount()
    }


  }
}