import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static values = { url: String, title: String, form: String, shape: String }

  initialize() {
    console.log("blah")
  }

  selectShape(e) {
    console.log(this.shapeValue)
    var select = document.getElementById('q_product_id_eq_any');
    select.options[this.shapeValue].selected ^= true
    this.filterForm()
  }

  connect() {
    console.log("connected partial")
    
    $(".shape_selector").on('select2:select select2:unselect', function () {
      console.log("list item selected");
      let event = new Event('change', { bubbles: true }) // fire a native event
      console.log(event)
      this.dispatchEvent(event);
    });

    load_hi_res()
    modelLoop()
  }

  filterForm() {
    console.log("form triggered")
    var form = document.getElementById('product_variation_search')

    var form_test = document.querySelector('#product_variation_search');
    var formData = new FormData(form_test);
    const data = [...formData.entries()];
    delete data[0]
    console.log(data)

    const asString = data
      .map(x => `${encodeURIComponent(x[0])}=${encodeURIComponent(x[1])}`)
      .join('&');

    history.pushState({
      id: 'homepage'
    }, 'Home | My App', window.location.pathname + '?' + asString);
    
    Rails.fire(form, 'submit');
  }

  change_model(e) {
    // fadeOut
    const photos = document.querySelector('#photos_holder');
    photos.classList.add('animate__animated', 'animate__fadeOut', 'animate__slow');

    const description = document.querySelector('#description_holder');
    description.classList.add('animate__animated', 'animate__fadeOut', 'animate__slow');

    
    
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })

    document.title =  this.titleValue

    history.pushState({
      id: 'homepage'
    }, 'Home | My App', this.urlValue);

    setTimeout(function() {
      console.log("running sticky")
      $('.js-sticky-block').removeAttr('style')
      $('.js-sticky-block').css('height', $('.js-sticky-block').outerHeight())
      $.HSCore.components.HSStickyBlock.init('.js-sticky-block');
    }, 1000);
  }

  addtoCart(e) {
    console.log("buying" + this.urlValue)
    addItemToCart(this.urlValue)
  }

}